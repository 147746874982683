import classnames from 'classnames';
import { CUSTOM_DIALOGS } from '../config/app';
import DialogStore from '../store/dialog-store';
import { downloadFile } from '../utility/download';
import { ENDPOINTS } from '../config/api';
import NotificationActions from '../action/notification-actions';
import Permission from '../service/permission-service';
import { queryPrefix } from '../utility/format';
import { request } from '../utility/request';
import { STATUS_TYPES } from '../config/export';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

const ANIMATE_IN = 100;
const ANIMATE_OUT = 500;

class Dialog extends Component {
    constructor(props) {
        super(props);

        let defaultOptions,
            localState = DialogStore.getState();

        // Add local fields using 'data' preffix
        defaultOptions = this.getDefaultOptions();
        localState.dataFields = {
            statusFrom: defaultOptions[0].value, // eslint-disable-line camelcase
            statusTo: defaultOptions[0].value, // eslint-disable-line camelcase
        };

        this.state = localState;
    }


    // Mounting
    componentDidMount() {
        DialogStore.listen(this.onChange.bind(this));
    }

    componentWillUnmount() {
        DialogStore.unlisten(this.onChange.bind(this));
    }


    // Event
    onChange() {
        this.setState(DialogStore.getState(), this.show.bind(this)); // Fire on change
    }


    // Getters
    getDefaultOptions() {
        let options = [
            {
                label: 'Draft',
                value: 'draft'
            }
        ];

        if (Permission.check('Results: Save as Ready For Review')) {
            options.push({
                label: 'Ready',
                value: 'ready_for_review'
            });
        }

        if (Permission.check('Results: Save as Approved')) {
            options.push({
                label: 'Approved',
                value: 'approved'
            });
        }

        return options;
    }


    // Helpers
    hide() {
        // Hide after timeout delay
        this.setState({ isVisible: false }, () => {
            // Hide once animated delay
            setTimeout(() => {
                this.setState({ isOpen: false });
            }, ANIMATE_OUT);
        });
    }

    isGreaterThan(fromStatus, toStatus) {
        if (fromStatus === 'draft' && toStatus === 'approved') {
            return true;
        }

        if (fromStatus === 'ready_for_review' && toStatus === 'draft') {
            return true;
        }

        if (fromStatus === 'approved' && (toStatus === 'ready_for_review' || toStatus === 'draft')) {
            return true;
        }

        return false;
    }

    show() {
        // Show after animate in delay
        setTimeout(() => {
            this.setState({ isVisible: true, show: false, loading: false });
        }, ANIMATE_IN);
    }

    submitBulkData() {
        let { data, dataFields } = this.state,
            endpoint;

        // Validation
        if (dataFields.statusFrom === dataFields.statusTo || this.isGreaterThan(dataFields.statusFrom, dataFields.statusTo)) {
            this.setState({ error: 'Invalid request. You may only change from \'draft\' to \'ready\' or from \'ready\' to \'approved\'.' });
            return false;
        }

        this.setState({
            error: '',
            loading: true
        });

        // Setup endpoint
        endpoint = `${ENDPOINTS.RESULTS_BULK_CHANGE}${dataFields.statusFrom}/${dataFields.statusTo}${data.endpointParams}`;

        request(endpoint, false, 'POST')
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                NotificationActions.push({
                    message: response.message,
                    timeout: 7000
                });

                this.state.data.reloadData();
                this.hide();
            })
            .catch((response) => {
                NotificationActions.push({
                    type: 'error',
                    message: response.message
                });

                this.hide();
            });
    }

    submitExport() {
        let { dataFields } = this.state,
            endpoint,
            queryString = DialogStore.getState().data,
            results = [];

        this.setState({
            error: '',
            loading: true
        });

        for (let key in dataFields) {
            if (dataFields[key] === true && STATUS_TYPES[key]) {
                results.push(STATUS_TYPES[key]);
            }
        }

        queryString += (results.length > 0) ? `${queryPrefix(queryString)}status=${results.join(',')}` : ''; // eslint-disable-line no-magic-numbers

        endpoint = `${ENDPOINTS.RESULTS_EXPORT}${queryString}`;

        request(endpoint)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (response.message) {
                    throw new Error(response.message);
                }
                downloadFile(response.data.download);

                this.hide();
            })
            .catch((error) => {
                this.setState({
                    error: `Error: ${error.message}`,
                    loading: false
                });
            });
    }


    // Handlers
    handleCancel() {
        this.hide();
    }

    handleContinue() {
        // Fire state continue function
        this.state.continueFunc();

        // This let's us only hide
        if (this.state.forceHide) {
            this.hide();
        } else {
            this.setState({ loading: true });
        }
    }

    handleOnChange(event) {
        let { dataFields: fields } = this.state,
            target = event.target;

        switch (target.type) {
            case 'checkbox':
                // Set true or false based on checkboxes value
                fields[target.getAttribute('name')] = target.checked;
                break;
            case 'text':
            default:
                // Update/add field values using name
                fields[target.getAttribute('name')] = target.value;
        }

        this.setState({ dataFields: fields });
    }

    handleBulkEditSubmit() {
        event.preventDefualt();

        this.submitBulkData();
    }

    handleExportSubmit(event) {
        event.preventDefault();

        this.submitExport();
    }


    // Custom dialogs
    renderBulkEditActions() {
        const { id } = this.state;
        let exportBtnText;

        switch (id) {
            case 'bulk-edit':
                exportBtnText = 'Change status';
                break;
            default:
                exportBtnText = 'Export';
        }

        return (
            <div role="group" className="button-group">
                <button className="button primary" onClick={this.submitBulkData.bind(this)}>{exportBtnText}</button>
                <button className="button secondary" onClick={this.handleCancel.bind(this)}>Cancel</button>
            </div>
        );
    }

    renderBulkEditContent() {
        let { total } = this.state.data;

        if (total) {
            return (
                <div className="content">
                    <h3>Bulk status change</h3>
                    <p>This bulk status change will affect {total} media results. Only the Global PR Manager or Senior PR Advisor are permitted to use this function.</p>
                    <form className="status-change-filter" onSubmit={this.handleBulkEditSubmit.bind(this)}>
                        <div className="inline-field">
                            <span className="text">Change all</span>
                            <select className="item" name="statusFrom" onChange={this.handleOnChange.bind(this)}>{this.renderStatusOptions()}</select>
                            <span className="text">results to</span>
                            <select className="item" name="statusTo" onChange={this.handleOnChange.bind(this)}>{this.renderStatusOptions()}</select>
                        </div>
                        {this.renderError()}
                    </form>
                </div>
            );
        }
    }

    renderExportResultsActions() {
        return (
            <div role="group" className="button-group">
                <button className="button primary" onClick={this.handleExportSubmit.bind(this)}>Export</button>
                <button className="button secondary" onClick={this.handleCancel.bind(this)}>Cancel</button>
            </div>
        );
    }

    renderExportResultsContent() {
        const canViewNonPublished = this.state.permissions.can_view_non_published;

        return (
            <div className="content">
                <h3>Export results</h3>
                <p className="text">Select the results type you wish to export:</p>
                <form className="status-change-filter" onSubmit={this.handleExportSubmit.bind(this)}>
                    {canViewNonPublished &&
                        <div className="check-item">
                            <label htmlFor="exportDraft">Draft</label>
                            <input role="checkbox" type="checkbox" name="exportDraft" id="exportDraft" onChange={this.handleOnChange.bind(this)}/>
                        </div>
                    }

                    {canViewNonPublished &&
                        <div className="check-item">
                            <label htmlFor="exportReady">Ready for review</label>
                            <input role="checkbox" type="checkbox" name="exportReady" id="exportReady" onChange={this.handleOnChange.bind(this)}/>
                        </div>
                    }

                    {canViewNonPublished &&
                        <div className="check-item">
                            <label htmlFor="exportApproved">Approved</label>
                            <input role="checkbox" type="checkbox" name="exportApproved" id="exportApproved" onChange={this.handleOnChange.bind(this)}/>
                        </div>
                    }
                    <div className="check-item">
                        <label htmlFor="exportPublished">Published</label>
                        <input role="checkbox" type="checkbox" name="exportPublished" id="exportPublished" onChange={this.handleOnChange.bind(this)}/>
                    </div>
                </form>
                {this.renderError()}
            </div>
        );
    }

    renderError() {
        let { error } = this.state;

        if (error) {
            return (<p className="error">{error}</p>);
        }
    }

    renderStatusOptions() {
        let options = this.getDefaultOptions();

        return options.map((option, index) => {
            return (<option value={option.value} key={index}>{option.label}</option>);
        });
    }


    // Render
    renderActions() {
        let { continueText, id } = this.state;

        switch (id) {
            case CUSTOM_DIALOGS.bulkEdit:
                return this.renderBulkEditActions();
            case CUSTOM_DIALOGS.exportResults:
                return this.renderExportResultsActions();
            default:
                return (
                    <div role="group" className="button-group">
                        <button className="button primary" onClick={this.handleContinue.bind(this)}>{continueText}</button>
                        <button className="button secondary" onClick={this.handleCancel.bind(this)}>Cancel</button>
                    </div>
                );
        }
    }

    renderContent() {
        let { heading, id, message } = this.state;

        // See if this specific dialog needs custom content
        switch (id) {
            case CUSTOM_DIALOGS.bulkEdit:
                return this.renderBulkEditContent();
            case CUSTOM_DIALOGS.exportResults:
                return this.renderExportResultsContent();
            default:
                return (
                    <div className="content">
                        <h3>{heading}</h3>
                        <p className="text">{message}</p>
                    </div>
                );
        }
    }

    render() {
        let dialogClass,
            innerClass,
            { isOpen, isVisible, loading } = this.state;

        dialogClass = classnames('alert-dialog', {
            'is-open': isOpen,
            'is-visible': isVisible
        });

        innerClass = classnames('inner', {
            'is-loading': loading
        });

        return (
            <div className={dialogClass}>
                <div className={innerClass}>
                    {this.renderContent()}
                    {this.renderActions()}
                </div>
            </div>
        );
    }
}

export default withRouter(Dialog);
