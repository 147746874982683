import React from 'react';
import { render } from 'react-dom';

// eslint-disable-next-line
import App from './App';
// import App from './Another';

import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { persistQueryClient, PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

// Implements client side query caching, currently only applicable to the Users query.
// Caches query response, then refetches in the background and updates the UI if the data has changed.
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: Infinity,
            refetchOnWindowFocus: false,
            retry: true,
        },
    },
});

const persister = createSyncStoragePersister({ storage: window.localStorage });

persistQueryClient({
    queryClient,
    persister
});

render(
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
        <App />
    </PersistQueryClientProvider>,
    document.getElementById('root')
);
