import DocumentTitle from '../utility/document-title';
import PropTypes from 'prop-types';
import UserActions from '../action/user-actions';
import UserStore from '../store/user-store';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

/**
 * A view containing a logout confirmation.
 */
class ConfirmLogout extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.state = this.initialState();
    }

    /**
     * Returns the initial state for this component.
     * @returns Object Component state
     */
    initialState() {
        return {
            isLoggedIn: UserStore.isLoggedIn(),
        };
    }

    // Event
    /**
     * Handles AltJS onChange events.
     */
    onChange() {
        const isLoggedIn = UserStore.isLoggedIn();

        this.setState({
            isLoggedIn
        });
    }

    /**
     * Handler for Logout button.
     */
    handleLogout() {
        fetch('/api/users/logout', {
            method: 'GET',
            headers: {
                'Cache-Control': 'no-cache',
            },
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                // @todo Implement user feedback.
                // TODO throw error
            }
        })
        .then((json) => {
            UserActions.logout();
            this.setState(this.initialState());
            window.location.href = json.location;
        })
        .catch(e => {
            // TODO Handle errors.
        });
    }

    /**
     * Handler for the Cancel button.
     */
    handleCancel() {
        this.props.history.goBack();
    }

    /**
     * Returns the JSX for action buttons.
     * @returns {JSX.Element}
     */
    renderActionButtons() {
        if (this.state.isLoggedIn) {
            return (
                <div role="group" className="form-actions">
                    <button className="button primary alt || logout" key="logout" onClick={this.handleLogout.bind(this)}>OK</button>
                    <button className="button secondary alt || cancel" key="cancel" onClick={this.handleCancel.bind(this)}>Cancel</button>
                </div>
            );
        }
    }

    render() {
        return (
            <DocumentTitle title="Confirm logout">
                <main role="main" className="main user confirm-logout">
                  <div className="main-inner">
                      <div className="page-content">
                          <h1 className="page-subheader || title">You will be logged out of all Tourism New Zealand applications. Do you wish to continue?</h1>
                          {this.renderActionButtons()}
                      </div>

                  </div>
              </main>
            </DocumentTitle>
        );
    }
}

ConfirmLogout.propTypes = {
    history: PropTypes.object
};

export default withRouter(ConfirmLogout);
